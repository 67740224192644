const axios = require('axios')
const api_base = process.env.VUE_APP_API_BASE_URL

const getInvoiceReport = (report) => {
    const url = `${api_base}/v1/invoices/report`

    const result = post(url, report)
        .catch(() => {
            console.log('ERROR GETTING TICKET REPORT')
            return {
                data: {
                    report: {}
                }
            }
        })

    return result
}

// PRIVATES
const post = async (url, data) => {
    const result = await axios.post(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status

            return retError
        })

    return result
}

module.exports = {
    getInvoiceReport
}